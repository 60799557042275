.header-wrapper {
  position: relative;
  /*min-height: 100vh;*/
/*  height: 200px;
  width: 100%;
  color: #fff;*/
    /*background: linear-gradient(90deg, #232526 0%, #414345 100%);*/
/*  clip-path: polygon(
      0 0,
      100% 0,
      100% 100%,
      0 calc(100% - 5vw)
    );*/
}

.header-shape {
  width: 100%;
  background: linear-gradient(90deg, #232526 0%, #414345 100%);
  position: absolute;
  /*top: -200px;*/

 }
