.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: #ffff;
  font-size: 1.1rem;
}

.navigator {
  padding-top: 15px;
  margin-bottom: 50px;
  z-index: 2;
}

.navbar-brand {
  color: #fff;
  padding-left: 30px;
}

.navbar-brand img {
  max-height: 50px;
}

.nav-link, .dropdown-toggle.nav-link {
  padding-right: 20px !important;
  padding-left: 20px !important;
  color: #fff;
  font-size: 1.1rem !important;
}

.nav-btn {
  margin-right: 30px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  line-height: initial;
}

.navbar-dark .navbar-toggler {
  border: none !important;
}

#basic-nav-dropdown {
  padding-right: 30px !important;
  padding-left: 30px !important;
  color: #fff !important;
  font-size: 1.1rem !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
}

.nav-dropdown-text {
  color: #fff !important;
  padding-right: 10px !important;
  font-size: 1.1rem !important;
}

.right-padding {
  padding-right: 30px;
}

@media (max-width: 576px) {
  .navbar-brand {
    padding-left: 0px;
  }

  .nav-link, .dropdown-toggle.nav-link  {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .nav-btn {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .right-padding {
    padding-right: 0px;
  }

  .navigator {
    margin-bottom: 25px;
  }
}

.landing-nav {

}

@media (max-width: 1200px) {
  .landing-nav {
    background: linear-gradient(90deg, #232526 0%, #414345 100%);
  }

  .right-nav {
    padding-left: 30px;
    right-padding: 0px !important;
  }

  .right-nav .nav-link {
    padding-left: 0px !important;
  }

  .demo-link {
    width: initial !important;
  }
}

@media (max-width: 568px) {
  .right-nav {
    padding-left: 0px;
  }
}
