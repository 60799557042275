.footer-container {
  padding: 50px;

  /*position: absolute;*/
  width: 100%;
  bottom: 0;
}

.footer {
  padding-top: 25px;
}

.footer-hr {
  margin-bottom: 35px;
}

.footer-link:not(:first-child) {
  margin-left: 15px;
}

.footer-link:hover {
  text-decoration: none;
  color: #FF7341;
}

.right-floater {
  float: right;
}

.footer-center a {
  margin-left: 20px;
  margin-right: 20px;
}

@media (max-width: 792px) {
  .right-floater {
    float: none;
   /* width: 100% !important;
    display: block !important;
    text-align: center !important;*/
  }

  .footer-container {
    text-align: center;
  }

  .footer-container div {
    margin-bottom: 7px;
  }
}

.lander-footer {
  background: linear-gradient(90deg, #232526 0%, #414345 100%);
}

.lander-footer p, .lander-footer span , .lander-footer a {
  color: #fff !important;
  font-size: 1.05rem !important;
}

.lander-footer a {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

@media (max-width: 576px) {
  .lander-footer a {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}

.lander-footer hr {
  display: none;
}