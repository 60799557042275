.account-type-card {
  width: 189px;
  background: #F8F9FE;
  color: #212529;
  border: 1px solid #eee;
  margin-top: 10px;
  margin-bottom: 25px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  border-radius: 4px;
  padding: 30px 25px;
}

.account-type-card:hover {
  background: #ffeee8;
  border: 1px solid #ff7f5022;
  cursor: pointer;
  transition: 0.3s ease;
}

.account-type-card p {
  font-size: 1.2rem;
  margin-top: 25px;
  font-weight: bold;
  margin-bottom: 0px;
}

.account-type-card img {
  width: 100%;
  height: 100px;
}